"use strict";
var slider = document.getElementById("slider");
var slides = document.querySelectorAll(".slider__slide");
var circlesLinks = document.querySelectorAll("#circles a");
for (var _i = 0, _a = Array.from(circlesLinks); _i < _a.length; _i++) {
    var link = _a[_i];
    link.addEventListener("click", function (e) {
        e.preventDefault();
        var href = e.currentTarget.getAttribute("href");
        var target = document.querySelector(href);
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var top = scrollTop + target.getBoundingClientRect().top - 100;
        window.scrollTo({ top: top, behavior: 'smooth' });
    });
}
var index = 0;
setInterval(function () {
    // Hide current slide after 3s
    var currentIndex = index;
    setTimeout(function () { return slides[currentIndex].classList.remove("active"); }, 4000);
    index++;
    if (index > slides.length - 1)
        index = 0;
    slider.removeChild(slides[index]);
    slider.append(slides[index]);
    setTimeout(function () { return slides[index].classList.add("active"); }, 10);
}, 4000);
